exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .inline-message_inline-message_3EzKK {\n    color: hsla(0, 100%, 100%, 1);\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: end;\n    -webkit-justify-content: flex-end;\n        -ms-flex-pack: end;\n            justify-content: flex-end;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    font-size: .8125rem;\n} .inline-message_success_1HE_O {\n    color: hsla(\n    0,\n    100%,\n    100%,\n    0.75\n);\n} .inline-message_info_8Giql {\n    color: hsla(0, 100%, 100%, 1);\n} .inline-message_warn_3ryT7 {\n    color: hsla(30, 100%, 70%, 1);\n} .inline-message_spinner_H-tr3 {\n    margin-right: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"inline-message": "inline-message_inline-message_3EzKK",
	"inlineMessage": "inline-message_inline-message_3EzKK",
	"success": "inline-message_success_1HE_O",
	"info": "inline-message_info_8Giql",
	"warn": "inline-message_warn_3ryT7",
	"spinner": "inline-message_spinner_H-tr3"
};