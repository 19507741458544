exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".controls_controls-container_3ZRI_ {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    margin-left: 75px;\n}\n", ""]);

// exports
exports.locals = {
	"controls-container": "controls_controls-container_3ZRI_",
	"controlsContainer": "controls_controls-container_3ZRI_"
};