import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import USBConnect from "../green-flag/usb.jsx";
import ConvPy from "../green-flag/convpy.jsx";

import GreenFlag from "../green-flag/green-flag.jsx";
import StopAll from "../stop-all/stop-all.jsx";
import TurboMode from "../turbo-mode/turbo-mode.jsx";
import { BrowserView, MobileView } from "react-device-detect";

import styles from "./controls.css";

const messages = defineMessages({
    goTitle: {
        id: "gui.controls.go",
        defaultMessage: "Go",
        description: "Green flag button title",
    },
    stopTitle: {
        id: "gui.controls.stop",
        defaultMessage: "Stop",
        description: "Stop button title",
    },
    UsbConnectTitle: {
        id: "gui.controls.USBConnect",
        defaultMessage: "Connect Device",
        description: "USB connect button title",
    },
    ConvPyTitle: {
        id: "gui.controls.ConvPy",
        defaultMessage: "Convert to python",
        description: "blocks to python",
    },
});

const Controls = function (props) {
    const {
        active,
        className,
        intl,
        onGreenFlagClick,
        onStopAllClick,
        onUSBConnectClick,
        onConvPyClick,
        turbo,
        ...componentProps
    } = props;
    return (
        <div
            className={classNames(styles.controlsContainer, className)}
            {...componentProps}
        >
            <GreenFlag
                active={active}
                title={intl.formatMessage(messages.goTitle)}
                onClick={onGreenFlagClick}
            />

            {turbo ? <TurboMode /> : null}
        </div>
    );
};

Controls.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    intl: intlShape.isRequired,
    onGreenFlagClick: PropTypes.func.isRequired,
    onUSBConnectClick: PropTypes.func.isRequired,
    onStopAllClick: PropTypes.func.isRequired,
    onConvPyClick: PropTypes.func.isRequired,
    turbo: PropTypes.bool,
};

Controls.defaultProps = {
    active: false,
    turbo: false,
};

export default injectIntl(Controls);
